<template>
    <div class="card" v-if="post.medias.length > 0">
        <div
            @click="changeArrow()"
            class="card-header"
            data-toggle="collapse"
            data-target="#collapseAttachments"
            aria-expanded="false"
            aria-controls="collapseAttachments"
            style="cursor: pointer"
            id="headingAttachments"
        >
            <a
                href="javascript:void(0);"
                class="card-title flex inline-flex justify-around"
            >
                <i class="fal mr-4 text-blue-500" :class="arrow"></i>
                <span class="text-lg text-blue-500">Pièces jointes</span>
            </a>
        </div>
        <div
            id="collapseAttachments"
            class="collapse"
            aria-labelledby="headingAttachments"
        >
            <div class="card-body">
                <div v-for="answer in post.answers" :key="answer.id">
                    <div v-if="answer.name == 'file-upload'">
                        <component
                            :medias="post.medias"
                            :is="answer.name"
                            :value="answer"
                            :post="post"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewFileUploadAttribute from './Attribute/PreviewFileUploadAttribute'

export default {
    name: 'PostPreviewAttachments',
    props: ['post'],
    components: {
        'file-upload': PreviewFileUploadAttribute
    },
    data: function () {
        return {
            arrow: 'fa-angle-up'
        }
    },
    methods: {
        changeArrow() {
            if (this.arrow == 'fa-angle-down') {
                this.arrow = 'fa-angle-up'
            } else {
                this.arrow = 'fa-angle-down'
            }
        }
    }
}
</script>
