<template>
  <div class='card mt-10'>
    <div
      @click='changeArrow()'
      class='card-header'
      data-toggle='collapse'
      data-target='#collapseInfo'
      aria-expanded='false'
      aria-controls='collapseInfo'
      style='cursor: pointer'
      id='headingInfo'
    >
      <a
        href='javascript:void(0);'
        class='card-title flex inline-flex justify-around'
      >
        <i class='fal mr-4 text-blue-500' :class='arrow'></i>
        <span class='text-lg text-blue-500'>{{
            $t('post.page.information.general')
          }}</span>
      </a>
    </div>
    <div v-if='post'
         id='collapseInfo'
         ref='collapseInfo'
         class='collapse show'
         aria-labelledby='headingInfo'
    >
      <div class='card-body'>
        <h6 class='text-base text-gray-500'>
          {{ $t('post.page.preview.description') }} :
        </h6>
        <p v-if='post.content' v-html='post.content'></p>
        <h6 class='text-base text-gray-500 mt-4'>
          {{ $t('post.page.preview.created_at') }} :
        </h6>
        <p v-if='post.created_at'>
          <i class='mr-2 far fa-calendar-alt text-blue-600'></i
          >{{
            moment(post.created_at)
              .locale($store.state.lang)
              .format('Do MMMM YYYY')
          }}
        </p>
        <h6 class='text-base text-gray-500 mt-4'>
          {{ $t('post.page.preview.edited_at') }} :
        </h6>
        <p v-if='post.updated_at'>
          <i class='mr-2 far fa-calendar-alt text-blue-600'></i
          >{{
            moment(post.updated_at)
              .locale($store.state.lang)
              .format('Do MMMM YYYY')
          }}
        </p>
        <h6 class='text-base text-gray-500 mt-4'>
          {{ $t('post.page.preview.form_name') }} :
        </h6>
        <p v-if='post.form && post.form.name'>
          <i class='mr-2 far fa-file-alt text-blue-600'></i
          >{{ post.form.name }}
        </p>
        <h6 class='text-base text-gray-500 mt-4'>
          {{ $t('post.page.preview.owner') }} :
        </h6>
        <div v-if="post.created_by">
          <p v-if='post.created_by.name'>
            <i class='mr-2 far fa-user text-blue-600'></i
            >{{ post.created_by.name }}
          </p>
          <p v-else-if='post.created_by.email'>
            <i class='mr-2 far fa-user text-blue-600'></i
            >{{ post.created_by.email }}
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PostPreviewInfo',
  props: ['post'],
  data: function() {
    return {
      arrow: 'fa-angle-down'
    }
  },
  methods: {
    changeArrow() {
      if (this.arrow == 'fa-angle-down') {
        this.arrow = 'fa-angle-up'
      } else {
        this.arrow = 'fa-angle-down'
      }
    }
  }
}
</script>
