<template>
  <div>
    <h6 class="has-text-weight-bold mt-4 text-base text-gray-500">
      {{ value.label }} :
    </h6>
    <p v-html="value.value.address"></p>
  </div>
</template>

<script>
export default {
  name: 'PreviewLocationAttribute',
  props: ['value'],
}
</script>

<style scoped></style>
