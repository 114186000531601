<template>
    <div class="card">
        <div
            @click="changeArrow()"
            class="card-header"
            data-toggle="collapse"
            data-target="#collapseAnswers"
            aria-expanded="false"
            aria-controls="collapseAnswers"
            style="cursor: pointer"
            id="headingAnswers"
        >
            <a
                href="javascript:void(0);"
                class="card-title flex inline-flex justify-around"
            >
                <i class="fal mr-4 text-blue-500" :class="arrow"></i>
                <span class="text-lg text-blue-500">{{
                    $t('post.page.preview.contribution')
                }}</span>
            </a>
        </div>
        <div
            id="collapseAnswers"
            class="collapse"
            aria-labelledby="headingAnswers"
        >
            <div class="card-body">
                <div v-for="answer in post.answers" :key="answer.id">
                    <div v-if="!['gdpr', 'file-upload'].includes(answer.name)">
                        <component
                            :is="answer.name"
                            :value="answer"
                            :post="post"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewLocationAttribute from './Attribute/PreviewLocationAttribute'
import PreviewCategoryAttribute from './Attribute/PreviewCategoryAttribute'
import PreviewClassicAttribute from './Attribute/PreviewClassicAttribute'
import CheckboxAttribute from './Attribute/CheckboxAttribute'

export default {
    name: 'PostPreviewAnswers',
    props: ['post'],
    components: {
        location: PreviewLocationAttribute,
        category: PreviewCategoryAttribute,
        smalltext: PreviewClassicAttribute,
        email: PreviewClassicAttribute,
        markdown: PreviewClassicAttribute,
        rating: PreviewClassicAttribute,
        'select-field': PreviewClassicAttribute,
        checkbox: CheckboxAttribute,
        radio: PreviewClassicAttribute,
        date: PreviewClassicAttribute,
        datetime: PreviewClassicAttribute,
        number: PreviewClassicAttribute
    },
    data: function () {
        return {
            arrow: 'fa-angle-up'
        }
    },
    methods: {
        changeArrow() {
            if (this.arrow == 'fa-angle-down') {
                this.arrow = 'fa-angle-up'
            } else {
                this.arrow = 'fa-angle-down'
            }
        }
    }
}
</script>
