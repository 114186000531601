<template>
    <div
        :id="id"
        class="modal fade js-modal-settings modal-backdrop-transparent"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        style="z-index: 1050"
    >
        <div class="modal-dialog modal-xs" :class="type">
            <div class="modal-content position-relative">
                <slot name="header"></slot>

                <div class="modal-body p-0">
                    <slot></slot>
                </div>
                <div class="modal-footer bg-white">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BsModal',
    props: {
        id: {
            required: true,
            description:
                "id de la modal qui sert à monter l'élement dans le DOM"
        },
        type: {
            required: false,
            default: '',
            description: `Use this to position the popup where you want.
                    Accepted values: modal-dialog-left, .modal-dialog-right, .modal-dialog-bottom, .modal-dialog-top or empty for default`
        }
    },
    components: {},
    data: function () {
        return {}
    },
    created() {},
    computed: {
        hasFooterSlot() {
            return !!this.$slots['footer']
        }
    },
    methods: {}
}
</script>

<style scoped>
.modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 1055;
}
</style>
