<template>
  <div>
    <h6 class="has-text-weight-bold mt-4 text-base text-gray-500">
      {{ value.label }}
    </h6>
    <div class="sm:col-span-2">
      <dd
        v-for="(file, index) in value.value"
        :key="index"
        class="mt-1 text-sm text-gray-900"
      >
        <PreviewUploadedFile :file="getMedia(file)"></PreviewUploadedFile>
      </dd>
    </div>
  </div>
</template>

<script>
import PreviewUploadedFile from '../../File/PreviewUploadedFile'

export default {
  name: 'PreviewFileUploadAttribute',
  props: ['value', 'medias'],
  components: {
    PreviewUploadedFile,
  },
  methods: {
    getMedia(id) {
      return this.medias.filter((media) => media.id == id)[0]
    },
  },
}
</script>

<style scoped></style>
