<template>
    <bs-modal :id="id" type="modal-dialog-right">
        <!-- Modal header slot template -->
        <template #header v-if="post">
            <div
                class="h-20 text-xl justify-between bg-blue-600 flex items-center"
            >
                <h6 class="m-0 text-white has-text-weight-bold ml-5">
                    #{{ post.reference }} - {{ post.title }}
                </h6>
                <a href="#">
                    <i @click="cancel" class="fal fa-times text-white mr-4"></i>
                </a>
            </div>
        </template>

        <!-- default slot modal template -->
        <div v-if="post" class="mt-10 flex flex-column">
            <div class="flex items-center flex-column space-x-4 mb-4">
                <div
                    class="flex items-start space-x-4 justify-center gap-4 w-full p-4 flex-wrap"
                >
                    <ButtonEditPriority variant="minimal" :post="post" />
                    <ButtonEditStatus variant="minimal" :post="post" />
                    <ButtonRemind variant="preview" :post="post" />
                    <ButtonExportPdf variant="minimal" :post="post" />
                    <a
                        @click="editTicket"
                        class="inline-flex items-center rounded-md bg-green-600"
                    >
                        <button
                            type="button"
                            class="inline-flex items-center h-9 px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <IconWrite class="-ml-1 mr-2 h-4 w-4" />
                            {{ $t('post.page.button.edit') }}
                        </button>
                    </a>
                </div>
            </div>

            <PostPreviewInfo :post="post"></PostPreviewInfo>
            <PostPreviewAnswers :post="post"></PostPreviewAnswers>
            <PostPreviewAttachments :post="post"></PostPreviewAttachments>
        </div>

        <!-- Modal footer slot template -->
        <template #footer v-if="post && post">
            <div class="flex inline-flex justify-center items-center">
                <a
                    class="inline-flex items-center rounded-md bg-green-600"
                    @click="showTicket"
                >
                    <button
                        type="button"
                        class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        {{ $t('post.page.preview.display') }}
                    </button>
                </a>
            </div>
        </template>
    </bs-modal>
</template>

<script>
import BsModal from '@/components/UI/BsModal'
import ModalMixin from '@/components/UI/ModalMixin'
import ButtonEditStatus from '../../UI/ButtonEditStatus'
import ButtonRemind from '../../UI/ButtonRemind'
import ButtonExportPdf from '../../UI/ButtonExportPdf'
import { mapState } from 'vuex'
import PostPreviewInfo from './PostPreviewInfo'
import PostPreviewAnswers from './PostPreviewAnswers'
import PostPreviewAttachments from './PostPreviewAttachments'
import ButtonEditPriority from './ButtonEditPriority'

export default {
    name: 'PostPreviewModal',
    mixins: [ModalMixin],
    components: {
        PostPreviewAnswers,
        BsModal,
        ButtonRemind,
        PostPreviewInfo,
        ButtonEditStatus,
        PostPreviewAttachments,
        ButtonExportPdf,
        ButtonEditPriority
    },
    data: function () {
        return {
            id: 'postPreview',
            statuses: null
        }
    },
    methods: {
        trigger: function () {
            this.modal.show()
        },
        cancel: function () {
            this.modal.hide()
        },
        showTicket: function () {
            this.cancel()
            this.$router.push(`/posts/${this.post.id}`)
        },
        editTicket: function () {
            this.cancel()
            this.$router.push(`/post/${this.post.id}/edit`)
        }
    },
    mounted() {
        this.create(this.id, {
            keyboard: false
        })
    },
    computed: {
        ...mapState('post', {
            post: (state) => state.post
        })
    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 1px;
    height: 10px;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: rgb(120, 183, 212);
    border-radius: 20px;
}
</style>
