import { render, staticRenderFns } from "./CheckboxAttribute.vue?vue&type=template&id=6925c7b8&scoped=true&"
import script from "./CheckboxAttribute.vue?vue&type=script&lang=js&"
export * from "./CheckboxAttribute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6925c7b8",
  null
  
)

export default component.exports