<template>
    <div>
        <button
            type="button"
            :class="{
                'buttonExport inline-flex justify-content-between items-center px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 w-36': true,
                'h-9': variant == 'minimal'
            }"
            @click.prevent="exportPDF()"
        >
            <IconDownload class="h-5 w-5" v-show="!loadingDownloadPdf" />
            <i
                v-show="loadingDownloadPdf"
                class="fas fa-circle-notch fa-spin mr-2 h-4 w-4 text-white"
            ></i>
            {{ $t('post.page.button.export') }}
        </button>

        <div id="postExport">
            <div :id="'mapImgDiv' + post.id"></div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    props: ['post', 'variant'],
    data() {
        return {
            loadingDownloadPdf: false,
            baseUrl: this.$store.state.baseURL,
            token: this.$store.state.auth.token
        }
    },
    methods: {
        ...mapActions('post', ['getPost', 'searchUsersList', 'exportPdfPost']),
        exportPDF() {
            this.loadingDownloadPdf = true
            var map = document.getElementById('myMap')

            if (map) {
                map.style.height = 'calc(100vh - 500px)'
                this.delay(3000).then(() => {
                    domtoimage.toPng(map).then(function (dataUrl) {
                        var img = new Image()
                        img.src = dataUrl
                        img.setAttribute('id', 'mapImg' + this.post.id)
                        var divImg = document.getElementById(
                            'mapImgDiv' + this.post.id
                        )
                        divImg.appendChild(img)
                        map.style.height = 'calc(100vh - 330px)'
                    })
                    this.exportPDFHandler()
                })
            } else {
                this.exportPDFHandler()
            }
        },
        delay(time) {
            return new Promise((resolve) => setTimeout(resolve, time))
        },
        exportPDFHandler() {
            this.delay(3000).then(() => {
                var divImg = document.getElementById('mapImg' + this.post.id)
                var mapView = null
                if (divImg) {
                    var pdfView = {
                        name: `map_img_post_${this.post.id}`,
                        ext: `png`,
                        encodedFile: divImg.src
                    }
                    mapView = { mapImg: pdfView }
                }
                this.exportPdfPost(mapView)
                    .then(() => {
                        const link = document.createElement('a')
                        link.href = `${this.baseUrl}/storage/posts/${this.post.reference}/export/pdf/download?token=${this.token}`
                        link.download
                        link.click()
                    })
                    .finally(() => {
                        this.loadingDownloadPdf = false
                    })
            })
        }
    }
}
</script>
