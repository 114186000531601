<template>
    <div>
        <ul
            role="list"
            class="border border-gray-200 rounded-md divide-y divide-gray-200"
        >
            <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
                <div class="w-0 flex-1 flex items-center">
                    <IconPaperClip
                        class="flex-shrink-0 h-5 w-5 text-gray-400"
                    />
                    <span class="ml-2 flex-1 w-0 truncate">{{
                        file.name
                    }}</span>
                </div>
                <div class="ml-4">
                    <a
                        :href="`${baseUrl}/storage/download/${file.id}?token=${token}`"
                        :download="file.name"
                        class="pl-2 font-medium text-blue-600 hover:text-blue-500"
                    >
                        {{ $t('post.file_upload.download') }}
                    </a>
                </div>
            </li>
        </ul>
        <div
            v-if="
                ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG'].includes(
                    file.name.split('.').pop()
                )
            "
            class="focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-blue-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
        >
            <img
                alt="image"
                :src="`${baseUrl}/storage/${file.id}?token=${token}`"
            />
        </div>
    </div>
</template>

<script>
import IconPaperClip from '@/components/icons/iconPaperClip.vue'

export default {
    name: 'PreviewUploadedFile',
    components: { IconPaperClip },
    props: ['file'],
    data() {
        return {
            baseUrl: this.$store.state.baseURL,
            token: this.$store.state.auth.token
        }
    }
}
</script>

<style scoped></style>
